<template>
  <section>
    <b-card class="mb-1">
      <b-card-title class="mb-1">
        Servicio de salud - Reporte de atenciones
      </b-card-title>
      <b-card-sub-title class="mb-2">
        Seleccione el intervalo de fechas para calcular el reporte
      </b-card-sub-title>
      <b-row>
        <b-col
          xl="6"
          lg="6"
          md="6"
          sm="12"
        >
          <label for="example-datepicker">Fecha Inicio</label>
          <b-form-datepicker
            v-model="fechaInicio"
            class="mb-1"
            @input="myProvider"
          />
        </b-col>
        <b-col
          xl="6"
          lg="6"
          md="6"
          sm="12"
        >
          <label for="example-datepicker">Fecha Fin</label>
          <b-form-datepicker
            v-model="fechaFin"
            class="mb-1"
            @input="myProvider"
          />
        </b-col>
      </b-row>
    </b-card>
    <b-row v-if="verGrafico">
      <b-col
        v-for="(i, index) in items"
        :key="index"
        xl="6"
        lg="6"
        md="6"
        sm="12"
      >
        <atencion-donut-chart
          :titulo="i.nombre"
          :items="i.datos"
        />
      </b-col>
    </b-row>
    <b-card>
      <b-row class="mb-1">
        <b-col cols="12">
          <div class="float-right">
            <b-dropdown
              id="dropdown-1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              text="Descargar"
              variant="primary"
            >
              <b-dropdown-item @click="descargarPdf">
                Descargar PDF
              </b-dropdown-item>
              <b-dropdown-item @click="descargarExcel">
                Descargar Excel
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="7"
          class=""
        >
          <label>Mostrar</label>
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="sm"
            class="per-page-selector d-inline-block mx-50"
            style="width:auto"
            @change="actualizar_to_from"
          />
          <label>registros</label>
        </b-col>

        <b-col
          cols="5"
          class="mb-1"
        >
          <b-form-group
            label=""
            label-for="filter-input"
            label-cols-sm="3"
            label-align-sm="right"
            class="mb-0"
          >
            <b-input-group>
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Buscar..."
                size="sm"
              />

              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  variant="success"
                  size="sm"
                  @click="filter = ''"
                >
                  Limpiar
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <b-table
        :items="atenciones"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        head-variant="light"
        class="position-relative"
        show-empty
        hover
        bordered
        responsive
        :empty-text="'No hay registros para mostrar'"
        :empty-filtered-text="'No hay registros que coincidan con su solicitud.'"
        @filtered="onFiltered"
      >
        <template #cell(index)="row">
          {{ from + row.index }}
        </template>

      </b-table>
      <b-row
        cols="12"
        class="d-flex justify-content-between my-1 mb-md-0"
      >
        <b-col cols="6">
          <span class="text-muted">Mostrando registros del {{ from }} al {{ to }} de un total de {{ totalRows }} registros</span>
        </b-col>
        <b-col cols="4">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
            @input="actualizar_to_from"
          />
        </b-col>

      </b-row>

    </b-card>
  </section>
</template>

<script>
import {
  BCard, BRow, BCol, BFormDatepicker, BCardTitle, BCardSubTitle, BDropdown, BDropdownItem,
  BFormSelect, BButton, BFormGroup, BFormInput, BInputGroup, BPagination, BTable, BInputGroupAppend,
} from 'bootstrap-vue'
import moment from 'moment'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import reporteStoreModule from './reporteStoreModule'
import AtencionDonutChart from './AtencionDonutChart.vue'

export default {
  components: {
    // BTr,
    // BTh,
    BCard,
    BFormDatepicker,
    BCardTitle,
    BCardSubTitle,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    BButton,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BInputGroup,
    BPagination,
    BTable,
    BInputGroupAppend,
    AtencionDonutChart,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      hoy: moment().format('YYYY-MM-DD'),
      fechaInicio: null,
      fechaFin: null,
      atenciones: [],
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, 100],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      totalRows: 0,
      to: 0,
      from: 0,
      fields: [
        { key: 'index', label: 'N°' },
        { key: 'cod_atencion', label: 'N° FUA' },
        { key: 'cod_historia_clinica', label: 'cod. hist' },
        { key: 'facultad', label: 'facultad' },
        { key: 'escuela', label: 'escuela' },
        { key: 'etnia', label: 'etnia' },
        { key: 'numero_documento', label: 'dni' },
        { key: 'paciente', label: 'apellidos y nombres' },
        { key: 'servicio', label: 'servicio' },
        { key: 'fecha_atencion', label: 'fech. aten' },
        { key: 'responsable_atencion', label: 'responsable' },
        { key: 'estado', label: 'estado' },
        { key: 'modalidad_atencion', label: 'mod. aten' },
        { key: 'tipo', label: 'tipo' },
        { key: 'derivado', label: 'derivado' },
      ],
      items: {},
      verGrafico: false,

    }
  },
  created() {
    this.fechaInicio = this.hoy
    this.fechaFin = this.hoy
    this.myProvider()
  },

  methods: {
    myProvider() {
      this.verGrafico = false
      const DATA = {
        fecha_inicio: this.fechaInicio,
        fecha_fin: this.fechaFin,
      }
      store.dispatch('salud-reporte-atenciones/getReporteAtenciones', DATA)
        .then(response => {
          this.atenciones = response.data.data
          this.totalRows = response.data.data.length
          const data = []
          Object.entries(response.data.appends).forEach(([key, value]) => {
            data.push({ nombre: `${key}`, datos: value })
          })
          this.items = data
          this.verGrafico = true
          this.actualizar_to_from()
        })
    },
    actualizar_to_from() {
      const { totalRows } = this
      if (totalRows === 0) {
        this.from = 0
        this.to = 0
      } else
      if (this.currentPage === 1) {
        this.from = 1
        if (totalRows <= this.perPage) {
          this.to = totalRows
        } else {
          this.to = this.perPage
        }
      } else {
        this.from = ((this.currentPage - 1) * this.perPage) + 1
        if ((this.currentPage * this.perPage) <= totalRows) {
          this.to = this.currentPage * this.perPage
        } else {
          this.to = totalRows
        }
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
      this.actualizar_to_from()
    },
    async descargarPdf() {
      const DATA = {
        fecha_inicio: this.fechaInicio,
        fecha_fin: this.fechaFin,
        hoy: this.hoy,
      }
      await store.dispatch('salud-reporte-atenciones/getReportePdf', DATA)
    },
    async descargarExcel() {
      const DATA = {
        fecha_inicio: this.fechaInicio,
        fecha_fin: this.fechaFin,
        hoy: this.hoy,
      }
      await store.dispatch('salud-reporte-atenciones/getReporteExcel', DATA)
    },
  },
  setup() {
    const SALUD_REPORTE_ATENCIONES = 'salud-reporte-atenciones'

    // Register module
    if (!store.hasModule(SALUD_REPORTE_ATENCIONES)) store.registerModule(SALUD_REPORTE_ATENCIONES, reporteStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SALUD_REPORTE_ATENCIONES)) store.unregisterModule(SALUD_REPORTE_ATENCIONES)
    })

    return {}
  },
}
</script>
