<template>
  <b-card>
    <b-card-title class="mb-1">
      Atenciones {{ titulo.replaceAll('_', ' ') }}
    </b-card-title>
    <!-- <b-card-sub-title class="mb-2">
      ..............
    </b-card-sub-title> -->

    <vue-apex-charts
      type="donut"
      height="350"
      :options="donutChart.chartOptions"
      :series="donutChart.series"
    />
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle,
  // BCardSubTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardTitle,
    // BCardSubTitle,
  },
  props: {
    items: {
      type: Object,
      default() {
        return {}
      },
    },
    titulo: {
      type: [String, null],
      default: null,
    },
  },
  data() {
    return {
      donutChart: {
        series: [],
        chartOptions: {
          legend: {
            show: true,
            position: 'bottom',
            fontSize: '14px',
            fontFamily: 'Montserrat',
          },
          // colors: [],
          dataLabels: {
            enabled: true,
            formatter(val) {
              // eslint-disable-next-line radix
              return `${parseInt(val)}%`
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: '2rem',
                    fontFamily: 'Montserrat',
                  },
                  value: {
                    fontSize: '1rem',
                    fontFamily: 'Montserrat',
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)} Becarios`
                    },
                  },
                  total: {
                    show: true,
                    fontSize: '1.5rem',
                    label: 'Total',
                    // formatter(val) {
                    //   return `${val.config.series[0]} Becarios`
                    // },
                  },
                },
              },
            },
          },
          labels: [],
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: '1.5rem',
                        },
                        value: {
                          fontSize: '1rem',
                        },
                        total: {
                          fontSize: '1.5rem',
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
        },
      },
    }
  },
  beforeMount() {
    this.donutChart.series = this.retornarSeries(this.items)
    this.donutChart.chartOptions.labels = this.retornarLabels(this.items)
  },
  methods: {
    retornarLabels(newval) {
      const data = []
      Object.entries(newval).forEach(([key]) => {
        data.push(`${key}`)
      })
      return data
    },
    retornarSeries(newval) {
      const data = []
      Object.entries(newval).forEach(([, value]) => {
        data.push(value.length)
      })
      return data
    },
  },
}
</script>
